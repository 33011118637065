import makeStyles from '@mui/styles/makeStyles';

const useAuthStyles = makeStyles((theme) => ({
	authBox: {
		width: 320,
	},

	loginActionBox: {
		display: 'flex',
		justifyContent: 'end',
		paddingTop: '1rem',
		fontSize: 14,
		color: `${theme.palette.light.dark} !important`,
	},

	logo: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: theme.spacing(4),
	},
}));

export default useAuthStyles;
