import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { AvatarWrapper, GridWrapper, MenuHeader, MenuItemHeader, sxAvatar } from './style';
import { Divider, Skeleton } from '@mui/material';
import { RiHome2Line, RiSettings2Line, RiUser2Line } from 'react-icons/ri';
import DesktopMenus from './DesktopMenus';
import MobileMenu from './MobileMenu';
import { LOGO } from 'assets/images';
import { AppURLConstant, URLConstant } from 'constants/URLConstant';
import { useLocation, Link } from 'react-router-dom';
import useUser from 'helpers/Hooks/useUser';
import AppContext from 'helpers/ContextProvider/AppContext';
import useAppLayoutStyles from 'assets/styles/Containers/AppLayoutStyles';
import { generateInitials } from 'helpers/Utils/commonHelper';

function NavigationBar() {
	const classes = useAppLayoutStyles();
	const location = useLocation();

	const { data, loading } = useUser();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const { logout } = React.useContext(AppContext);

	const navigationMenus = [
		{
			name: 'Home',
			icon: <RiHome2Line />,
			link: URLConstant.DASHBOARD,
			subject: 'Ingestion',
		},
		{
			name: 'User Management',
			icon: <RiUser2Line />,
			link: URLConstant.USERS,
			subject: 'ETL',
		},
		{
			name: 'Settings',
			icon: <RiSettings2Line />,
			link: URLConstant.SETTINGS,
			subject: 'Visualize',
		},
	];

	const defaultMenu = React.useMemo(() => {
		const menu = navigationMenus.filter((item) => item.link === location.pathname);
		return menu?.[0]?.label;
		// eslint-disable-next-line
	}, []);

	return (
		<AppBar position="static" color="inherit">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box className={classes.appLogo} sx={{ display: { xs: 'none', md: 'flex' } }}>
						<img src={LOGO.default} alt="" />
					</Box>

					<MobileMenu menus={navigationMenus} />

					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, paddingX: { xs: 1, md: 2 } }}>
						<DesktopMenus defaultKey={defaultMenu} lists={navigationMenus} />
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						<GridWrapper>
							<Box
								sx={{ display: { xs: 'none', md: 'flex' } }}
								display="flex"
								flexDirection="column"
								paddingRight={2}
								justifyContent="end">
								<Typography
									sx={{
										fontWeight: 600,
										fontSize: 14,
										textAlign: 'end',
									}}>
									{loading ? <Skeleton width={80} /> : data?.me?.firstName}
								</Typography>
								<Typography
									sx={{
										fontSize: 14,
										color: '#B7B7B7',
										textAlign: 'end',
									}}>
									{loading ? <Skeleton width={80} /> : data?.me?.email}
								</Typography>
							</Box>
							<AvatarWrapper id="avatar-profile-header" onClick={handleClick}>
								<Avatar sx={sxAvatar}>{generateInitials(data?.me?.firstName ?? '-')}</Avatar>
							</AvatarWrapper>
							<MenuHeader
								id="header-menu"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
								// sx={{ width: 196 }}
							>
								<Link to={AppURLConstant.USERS}>
									<MenuItemHeader>My Account</MenuItemHeader>
								</Link>

								<Box paddingX="20px">
									<Divider />
								</Box>
								<MenuItemHeader onClick={logout}>Log out</MenuItemHeader>
							</MenuHeader>
						</GridWrapper>
					</Box>
				</Toolbar>
			</Container>
			<Box>
				<Divider />
			</Box>
		</AppBar>
	);
}
export default NavigationBar;
