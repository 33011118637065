import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import NavigationBar from './NavigationBar';
import AppContext from 'helpers/ContextProvider/AppContext';
import { useLocation } from 'react-router-dom';
import { NO_HEADER_MENU } from 'constants/URLConstant';

const AppContainer = ({ children }) => {
	const location = useLocation();
	const { hasHeaderMenu, setHasHeaderMenu } = useContext(AppContext);

	useEffect(() => {
		if (NO_HEADER_MENU.includes(location.pathname)) {
			setHasHeaderMenu(false);
		} else {
			setHasHeaderMenu(true);
		}
	}, [setHasHeaderMenu, location.pathname]);

	return (
		<>
			<NavigationBar />
			{hasHeaderMenu ? <Box>{children}</Box> : <Container maxWidth="xl">{children}</Container>}
		</>
	);
};

AppContainer.propTypes = {
	children: PropTypes.any,
};

export default AppContainer;
