import React, { useEffect, useId, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
// import Button from 'src/components/Button';
// import Link from 'src/components/Link';
import { useLocation, Link } from 'react-router-dom';
import { Box, IconButton, Stack } from '@mui/material';
import { RiAlignLeft } from 'react-icons/ri';
// import { MenuHeader } from '../style';
import Button from 'components/Button';

const DesktopMenus = ({ lists, defaultKey }) => {
	const location = useLocation();
	const [selected, setSelected] = useState(defaultKey || '');
	const [anchorEl, setAnchorEl] = useState(null);
	const id = useId();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const selectedMemo = useMemo(() => {
		const active = lists?.filter((item) => location?.pathname?.includes(item?.link));
		return active?.[0]?.label || active?.[0]?.name || selected;
		// eslint-disable-next-line
	}, [location?.pathname, selected, lists]);

	useEffect(() => {
		if (location.pathname.includes('/app/')) {
			setSelected('Home');
		}
	}, [location]);

	return (
		<>
			{lists.map((item, index) => {
				const title = item?.title || item?.label || item?.name;
				if (index < 4) {
					return (
						<Link key={id + index} to={item?.link || '/'}>
							<Button onClick={() => setSelected(title)} variant={selectedMemo === title ? 'selected' : 'unSelected'}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<Box display="flex" alignItems="center">
										{item?.icon}
									</Box>
									<span>{title || '-'}</span>
								</Stack>
							</Button>
						</Link>
					);
				}
			})}
			{lists.length > 4 && (
				<>
					<IconButton onClick={handleClick}>
						<RiAlignLeft />
					</IconButton>
					<Box
						id="header-menu-nav"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}>
						{lists.map((item, index) => {
							const title = item?.title || item?.label;
							if (index > 3) {
								return (
									<Link key={id + index} to={item?.link || '/'}>
										<Button
											onClick={() => setSelected(title)}
											variant={[selected, selectedMemo].includes(title) ? 'selected' : 'unSelected'}>
											{title || '-'}
										</Button>
									</Link>
								);
							}
						})}
					</Box>
				</>
			)}
		</>
	);
};

DesktopMenus.propTypes = {
	lists: PropTypes.arrayOf(PropTypes.any),
	defaultKey: PropTypes.string,
};

export default DesktopMenus;
