import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Stack } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';

import { AuthMutation } from 'graphql/mutations';
import { AuthConstant, URLConstant } from 'constants/index';
import { isLoggedInVar } from 'store/cache';
import { errorHandler } from 'store/Links/errorLink';
import IntlMessages from 'helpers/Utils/IntlMessages';
import useAuthStyles from 'assets/styles/Containers/AuthStyles';
import InputField from 'components/InputField';
import Button from 'components/Button';

const LoginForm = ({ intl }) => {
	const toasts = useToasts();
	const classes = useAuthStyles();

	const [login, { loading }] = useMutation(AuthMutation.LOGIN, {
		onCompleted: (data) => {
			if (data['tokenAuth']['success']) {
				if (data['tokenAuth']['user']['isActive'] && data['tokenAuth']['user']['isStaff']) {
					localStorage.setItem(AuthConstant.AUTH_TOKEN, data['tokenAuth']['token']);
					localStorage.setItem(AuthConstant.AUTH_TOKEN_REFRESH, data['tokenAuth']['refreshToken']);
					isLoggedInVar(true);
				} else {
					toasts.addToast(<IntlMessages id={'login.failure.notStaff'} />, { appearance: 'error' });
				}
			} else {
				errorHandler(data['tokenAuth']);
			}
		},
	});

	const loginSchema = yup.object().shape({
		username: yup
			.string()
			.required(intl.formatMessage({ id: 'login.validation.email.required' }))
			.email(intl.formatMessage({ id: 'login.validation.email.valid' })),
		password: yup.string().required(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(loginSchema),
	});

	const { ref: usernameRef, ...usernameInputProps } = register('username');
	const { ref: passwordRef, ...passwordInputProps } = register('password');

	const onSubmit = (data) => {
		let variables = {
			email: data.username,
			password: data.password,
		};

		login({
			variables: variables,
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Stack spacing={3}>
				<InputField
					{...usernameInputProps}
					name="username"
					type="text"
					variant="outlined"
					inputRef={usernameRef}
					error={!!errors.username}
					helperText={errors.username ? errors.username.message : ''}
					placeholder={intl.formatMessage({ id: 'login.email' })}
					label={intl.formatMessage({ id: 'login.email' })}
					fullWidth
					required
				/>

				<InputField
					{...passwordInputProps}
					name="password"
					inputRef={passwordRef}
					error={!!errors.password}
					helperText={errors.password ? errors.password.message : ''}
					fullWidth
					required
					placeholder={intl.formatMessage({ id: 'login.password' })}
					label={intl.formatMessage({ id: 'login.password' })}
					variant="outlined"
					password
				/>
			</Stack>

			<Box className={classes.loginActionBox}>
				<Link component={RouterLink} to={URLConstant.DASHBOARD}>
					{intl.formatMessage({ id: 'login.forgotPassword' })}
				</Link>
			</Box>

			<Box mt={4}>
				<Button type="submit" disabled={!isValid || isSubmitting} fullWidth loading={loading} size="large">
					<IntlMessages id={'login.button'} />
				</Button>
			</Box>
		</form>
	);
};

LoginForm.propTypes = {
	onSubmit: PropTypes.func,
	intl: PropTypes.object.isRequired,
};

export default injectIntl(LoginForm);
