import React from 'react';
import { Route } from 'react-router';
import { Box, Container } from '@mui/material';
import { Switch } from 'react-router-dom';

import { LOGO } from 'assets/images';
import LoginForm from './LoginForm';
import { URLConstant } from 'constants/index';
import useAuthStyles from 'assets/styles/Containers/AuthStyles';
import AuthLayout from 'containers/AuthLayout';

const AuthContainer = () => {
	const classes = useAuthStyles();

	return (
		<AuthLayout>
			<Container sx={{ maxWidth: '400px !important', paddingY: 4 }}>
				<Box className={classes.logo}>
					<img src={LOGO.default} alt="" />
				</Box>
				<Switch>
					<Route path={URLConstant.LOGIN} component={LoginForm} />
				</Switch>
			</Container>
		</AuthLayout>
	);
};

export default AuthContainer;
