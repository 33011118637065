import { deepPurple, indigo } from '@mui/material/colors';
import palette from './palette';

const defaultTheme = {
	palette: {
		primary: {
			light: deepPurple[500],
			main: deepPurple[700],
			dark: deepPurple[900],
			contrastText: '#ffffff',
		},

		secondary: {
			light: indigo[500],
			main: indigo[700],
			dark: indigo[900],
			contrastText: '#ffffff',
		},
		...palette,
		typography: {
			fontFamily: `"Inter", sans-serif`,
		},
	},
};

export default defaultTheme;
