import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { Box, Button, ListItem, ListSubheader, Menu, MenuItem } from '@mui/material';

export const Header = styled('header')({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '12px 24px',
	background: '#ffffff',
	borderBottom: '1px solid #DBDBDB',
});
export const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
	},
	space: {
		padding: '0 20px',
	},
});
export const Logo = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
export const NavbarMenu = styled(Logo)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0 20px',
});
export const GridWrapper = styled(NavbarMenu)({
	display: 'flex',
});
export const SearchWrapper = styled(NavbarMenu)({
	width: 260,
});
export const NotificationWrapper = styled(NavbarMenu)({
	position: 'relative',
	padding: 0,
});
export const DotNotification = styled('div')({
	position: 'absolute',
	background: '#F7AC03',
	width: 16,
	height: 16,
	borderRadius: 99,
	border: 'solid 3px #ffffff',
	top: -5,
	right: -3,
});
export const AccountWrapper = styled(NavbarMenu)({
	display: 'block',
	textAlign: 'right',
});
export const AvatarWrapper = styled(NotificationWrapper)({ cursor: 'pointer' });
export const sxAvatar = { background: '#05908F', width: 36, height: 36, padding: 3, color: '#fff' };

export const BoxMobileNav = styled(Box)(() => ({
	// padding: '0 20px',
	// background: '#f8f4fd',
	position: 'relative',
	width: '80vw',
}));

export const ItemMenu = styled(Button)(({ theme }) => ({
	width: '100%',
	textTransform: 'uppercase',
	fontSize: 14,
	padding: '0.5rem 1rem',
	cursor: 'pointer',
	'&:hover': {
		background: theme.palette.primary.dark,
		color: '#ffffff',
		borderRadius: 8,
		outline: 'none',
	},
}));

export const LogoutButton = styled(Button)(() => ({
	width: '100%',
	textTransform: 'uppercase',
	fontSize: 14,
}));

export const ListItemWrapper = styled(ListItem)(() => ({
	padding: '0 1rem',
}));

export const ListSubHeader = styled(ListSubheader)({
	background: '#f8f4fd',
	marginBottom: '1rem',
	padding: 0,
});

export const ListContainerMobile = styled('li')({
	padding: 0,
	'& > ul': {
		padding: 0,
		paddingBottom: 70,
		'& li': {
			// padding: 0
		},
	},
});
export const MenuItemHeader = styled(MenuItem)({
	fontSize: 14,
	color: '#959595',
	fontWeight: 600,
	padding: '16px 20px',
});
export const MenuHeader = styled(Menu)(() => ({
	'& .MuiPaper-root': {
		minWidth: 196,
		marginTop: 10,
		background: '#ffffff',
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			right: 14,
			width: 10,
			height: 10,
			background: '#ffffff',
			transform: 'translateY(-50%) rotate(45deg)',
			zIndex: 0,
		},
	},
}));
