import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { LeftGrid, RightGrid, ContainerRightContent, RightContentWrapper, Title, SubTitle, BoxAuth } from './style';

const AuthLayout = ({ children }) => {
	return (
		<Grid container alignItems="stretch" sx={{ height: '100vh' }}>
			<LeftGrid item xs={12} md={6}>
				<BoxAuth>
					<Box>{children}</Box>
				</BoxAuth>
			</LeftGrid>
			<RightGrid item md={6} sx={{ display: { xs: 'none', md: 'flex' } }} flexDirection="column">
				<ContainerRightContent fixed>
					<RightContentWrapper>
						<Title>Lyrid Auth Service</Title>
						<SubTitle>
							Sheliak is an easily located third magnitude star southeast of Vega, marking the southwest corner of the skewed
							rectangular pattern of Lyra.
						</SubTitle>
					</RightContentWrapper>
				</ContainerRightContent>
			</RightGrid>
		</Grid>
	);
};

AuthLayout.propTypes = {
	children: PropTypes.any,
};

export default AuthLayout;
