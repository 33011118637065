import { styled } from '@mui/system';
import { Container, Grid } from '@mui/material';

export const LeftGrid = styled(Grid)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
export const BoxAuth = styled('div')(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		width: 'auto',
	},
	[theme.breakpoints.up('md')]: {
		width: '100%',
	},
}));
export const ImageWrapper = styled('div')({
	padding: '0px 50px 50px 50px',
});
export const Footer = styled('div')({
	padding: 20,
	textAlign: 'center',
	fontSize: 10,
});
export const ContainerRightContent = styled(Container)({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});
export const RightGrid = styled(Grid)({
	background: 'linear-gradient(218deg, rgba(92,78,203,1) 0%, rgba(103,58,183,1) 100%)',
	height: 'auto',
	minHeight: 775,
});
export const Title = styled('h2')(({ theme }) => ({
	fontSize: '2rem',
	color: theme.palette.light.contrastText,
	cursor: 'default',
}));
export const RightContentWrapper = styled('div')(() => ({
	textAlign: 'center',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	maxWidth: 510,
	margin: '0 auto',
}));
export const SubTitle = styled('p')(({ theme }) => ({
	fontSize: '1rem',
	color: theme.palette.light.contrastText,
	cursor: 'default',
}));
export const SpanWrap = styled('span')(() => ({
	boxSizing: 'border-box',
	display: 'inline-block',
	overflow: 'hidden',
	width: 'initial',
	height: 'initial',
	background: 'none',
	opacity: 1,
	border: '0',
	margin: '0',
	padding: '0',
	position: 'relative',
	maxWidth: '100%',
	borderRadius: 10,
}));
export const SpanWrapImg = styled('span')(() => ({
	boxSizing: 'border-box',
	display: 'block',
	width: 'initial',
	height: 'initial',
	background: 'none',
	opacity: 1,
	border: '0',
	margin: '0',
	padding: '0',
	maxWidth: '100%',
}));
export const ImgSpan = styled('img')(() => ({
	display: 'block',
	maxWidth: '100%',
	width: 'initial',
	height: 'initial',
	background: 'none',
	opacity: 1,
	border: '0',
	margin: '0',
	padding: '0',
}));
export const Image = styled('img')(() => ({
	borderRadius: 10,
	position: 'absolute',
	top: '0',
	left: '0',
	bottom: '0',
	right: '0',
	boxSizing: 'border-box',
	padding: '0',
	border: 'none',
	margin: 'auto',
	display: 'block',
	width: '0',
	height: '0',
	minWidth: '100%',
	maxWidth: '100%',
	minHeight: '100%',
	maxHeight: '100%',
}));
