import React, { useState } from 'react';
import { Box, IconButton, Stack, SwipeableDrawer, Typography, styled } from '@mui/material';
import { RiMenuLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGO } from 'assets/images';
import useAppLayoutStyles from 'assets/styles/Containers/AppLayoutStyles';

const ItemMenu = styled(Box)(({ theme, active }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: 8,
	padding: '12px',
	borderRadius: 8,
	color: active ? theme.palette.primary.main : theme.palette.black.main,
	backgroundColor: active ? theme.palette.primary.light : '#fff',
	'&:hover, &:active': {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
}));

const MobileMenu = ({ menus }) => {
	const classes = useAppLayoutStyles();
	const history = useHistory();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const handleMenu = () => setOpen(!open);

	const handleClick = (link) => {
		handleMenu();
		history.push(link);
	};

	return (
		<>
			<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenu}
					color="inherit">
					<RiMenuLine />
				</IconButton>
			</Box>
			<SwipeableDrawer
				anchor="left"
				open={open}
				onOpen={handleMenu}
				onClose={handleMenu}
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}>
				<Box paddingX={4} paddingY={2} width="80vw" height="100%">
					<Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', marginBottom: 5, paddingTop: 2 }}>
						<Box className={classes.appLogo}>
							<img src={LOGO.default} alt="" />
						</Box>
					</Box>
					<Stack spacing={1}>
						{menus.map((menu, index) => {
							const isActive = location.pathname.includes(menu.link);
							return (
								<ItemMenu onClick={() => handleClick(menu.link)} key={index} active={isActive.toString()}>
									{menu.icon}
									<Typography fontSize={14} fontWeight={600}>
										{menu.subject}
									</Typography>
								</ItemMenu>
							);
						})}
					</Stack>
				</Box>
			</SwipeableDrawer>
		</>
	);
};

MobileMenu.propTypes = {
	menus: PropTypes.any.isRequired,
};

export default MobileMenu;
