import { useQuery } from '@apollo/client';
import { AuthQuery } from 'graphql/queries';
import _ from 'lodash';

const useUser = () => {
	const { data: me, loading } = useQuery(AuthQuery.GET_ME_USER, { errorPolicy: 'ignore', fetchPolicy: 'cache-and-network' });
	const fullName = _.get(me, 'me.fullName', '');
	const email = _.get(me, 'me.email', '');
	return { data: me, fullName, email, loading };
};

export default useUser;
