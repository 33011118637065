import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const ButtonWrapper = styled(Button)((props) => ({
	borderRadius: 8,
	width: props.autowidth ? 'auto' : '100%',
	textTransform: 'initial',
	fontWeight: 'bold',
}));

export const ContentWrapper = styled('div')(({ width }) => ({
	width,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'& span': {
		padding: '0px 5px',
	},
}));
export const LoadingWrapper = styled('span')({
	display: 'flex',
	alignItems: 'center',
	padding: '0 5px',
	opacity: '.5',
});
